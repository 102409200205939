import KKStudioProjects  from '../assets/images/gallery/KKStudioProjectsCover.jpeg';
import PortraitImage  from '../assets/images/gallery/portraitImage.jpg';

export const KK_STUDIO_PROJECTS = [
    { 
        name: '1',
        thumbnailImage: KKStudioProjects,
        title: 'GRABÓWKA SEGMENT',
        description: `
            Projekt parteru oraz łazienek na piętrze w domu jednorodzinnym w Konstancinie, o łącznej powierzchni 93m2. Styl domu- 
            połączenie retro- skandynawskiego, loftowego i minimalistycznego. Bez zmiany funkcji pomieszczeń, kolorystyka bazowa – szarości, ecru, 
            offwhite oraz dodatki egzotycznego drewna w postaci mebli. Oraz schodów wykonanych na zamówienie. Dwustronny kominek łączący salon z jadalnią, duże okna, 
            otwarte przestrzenie. Projekt i realizacja 2015 rok. Jest to jeden z projektów, z których od początku do końca jestem dumna i wykonywałam z przyjemnością. 
            Wizualizacje: Artur Budny, Foto: Łukasz Matczak
        `,
        flatArea: 98,
        gallery: [
            {
                original: KKStudioProjects,
                thumbnail: KKStudioProjects,
            },
            {
                original: PortraitImage,
                thumbnail: PortraitImage,
            },
            {
                original: PortraitImage,
                thumbnail: PortraitImage,
            }
        ],
        inCooperation: false,
        cooperationCompany: '',
        visualizationsAuthors: 'Artur Budny',
        photosAuthors: 'Łukasz Matczak'
    },
    { 
        name: '2',
        thumbnailImage: KKStudioProjects,
        title: 'WARSZAWA MIESZKANIE MOKOTÓW',
        description: `
            Projekt parteru oraz łazienek na piętrze w domu jednorodzinnym w Konstancinie, o łącznej powierzchni 93m2. Styl domu- 
            połączenie retro- skandynawskiego, loftowego i minimalistycznego. Bez zmiany funkcji pomieszczeń, kolorystyka bazowa – szarości, ecru, 
            offwhite oraz dodatki egzotycznego drewna w postaci mebli. Oraz schodów wykonanych na zamówienie. Dwustronny kominek łączący salon z jadalnią, duże okna, 
            otwarte przestrzenie. Projekt i realizacja 2015 rok. Jest to jeden z projektów, z których od początku do końca jestem dumna i wykonywałam z przyjemnością. 
            Wizualizacje: Artur Budny, Foto: Łukasz Matczak
        `,
        flatArea: 50,
        gallery: [
            {
                original: KKStudioProjects,
                thumbnail: KKStudioProjects
            },
            {
                original: PortraitImage,
                thumbnail: PortraitImage
            }
        ],
        inCooperation: false,
        cooperationCompany: '',
        visualizationsAuthors: 'Artur Budny',
        photosAuthors: 'Łukasz Matczak'
    },
    { 
        name: '3',
        thumbnailImage: KKStudioProjects,
        title: 'KONSTANCIN DOM',
        description: `
            Projekt parteru oraz łazienek na piętrze w domu jednorodzinnym w Konstancinie, o łącznej powierzchni 93m2. Styl domu- 
            połączenie retro- skandynawskiego, loftowego i minimalistycznego. Bez zmiany funkcji pomieszczeń, kolorystyka bazowa – szarości, ecru, 
            offwhite oraz dodatki egzotycznego drewna w postaci mebli. Oraz schodów wykonanych na zamówienie. Dwustronny kominek łączący salon z jadalnią, duże okna, 
            otwarte przestrzenie. Projekt i realizacja 2015 rok. Jest to jeden z projektów, z których od początku do końca jestem dumna i wykonywałam z przyjemnością.
        `,
        flatArea: 93,
        gallery: [
            {
                original: KKStudioProjects,
                thumbnail: KKStudioProjects
            },
            {
                original: PortraitImage,
                thumbnail: PortraitImage,
            }
        ],
        inCooperation: true,
        cooperationCompany: 'www.abu-wnetrza.com',
        visualizationsAuthors: 'Artur Budny',
        photosAuthors: 'Łukasz Matczak'
    },
    { 
        name: '4',
        thumbnailImage: KKStudioProjects,
        title: 'IGNATKI DOM',
        description: `
            Projekt parteru oraz łazienek na piętrze w domu jednorodzinnym w Konstancinie, o łącznej powierzchni 93m2. Styl domu- 
            połączenie retro- skandynawskiego, loftowego i minimalistycznego. Bez zmiany funkcji pomieszczeń, kolorystyka bazowa – szarości, ecru, 
            offwhite oraz dodatki egzotycznego drewna w postaci mebli. Oraz schodów wykonanych na zamówienie. Dwustronny kominek łączący salon z jadalnią, duże okna, 
            otwarte przestrzenie. Projekt i realizacja 2015 rok. Jest to jeden z projektów, z których od początku do końca jestem dumna i wykonywałam z przyjemnością. 
            Wizualizacje: Artur Budny, Foto: Łukasz Matczak
        `,
        flatArea: 130,
        gallery: [
            {
                original: KKStudioProjects,
                thumbnail: KKStudioProjects
            },
            {
                original: PortraitImage,
                thumbnail: PortraitImage
            }
        ],
        inCooperation: false,
        cooperationCompany: '',
        visualizationsAuthors: 'Artur Budny',
        photosAuthors: 'Łukasz Matczak'
    },
    { 
        name: '5',
        thumbnailImage: KKStudioProjects,
        title: 'IGNATKI DOM',
        description: `
            Projekt parteru oraz łazienek na piętrze w domu jednorodzinnym w Konstancinie, o łącznej powierzchni 93m2. Styl domu- 
            połączenie retro- skandynawskiego, loftowego i minimalistycznego. Bez zmiany funkcji pomieszczeń, kolorystyka bazowa – szarości, ecru, 
            offwhite oraz dodatki egzotycznego drewna w postaci mebli. Oraz schodów wykonanych na zamówienie. Dwustronny kominek łączący salon z jadalnią, duże okna, 
            otwarte przestrzenie. Projekt i realizacja 2015 rok. Jest to jeden z projektów, z których od początku do końca jestem dumna i wykonywałam z przyjemnością. 
            Wizualizacje: Artur Budny, Foto: Łukasz Matczak
        `,
        flatArea: 130,
        gallery: [
            {
                original: KKStudioProjects,
                fullSrc: KKStudioProjects
            },
            {
                original: PortraitImage,
                thumbnail: PortraitImage
            }
        ],
        inCooperation: false,
        cooperationCompany: '',
        visualizationsAuthors: 'Artur Budny',
        photosAuthors: 'Łukasz Matczak'
    }
];

export const EMPTY_PROJECT ={ 
    name: '-1',
    thumbnailImage: '',
    title: '',
    description: '',
    flatArea: 0,
    gallery: [],
    inCooperation: false,
    cooperationCompany: '',
    visualizationsAuthors: '',
    photosAuthors: ''
};