import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';
import PropTypes from "prop-types";

import { getPageIsLoadingData } from '../selectors/generalSettingsSelectors.js';

import LoadingDataComponent from '../components/loadingDataComponent';

const BlurWrapper = styled.div`
  filter: ${props => props.pageIsLoadingData ? 'blur(50px)' : 'none'};
  width: 100%;
`;


const withLoadingPageView = WrappedComponent => {
  class WithLoadingPageView extends React.Component {
    render() {
      const { pageIsLoadingData, routeConfig } = this.props;
      return (
        <React.Fragment>
          { pageIsLoadingData && <LoadingDataComponent /> }
          <BlurWrapper pageIsLoadingData={pageIsLoadingData}>
            <WrappedComponent {...routeConfig} />
          </BlurWrapper>
        </React.Fragment>
      );
    }
  }

  WithLoadingPageView.propTypes = {
    pageIsLoadingData: PropTypes.bool.isRequired
  };

  const mapStateToProps = state => {
    return {
      pageIsLoadingData: getPageIsLoadingData(state)
    }
  };

  return connect(
    mapStateToProps
  )(WithLoadingPageView);
};

export default withLoadingPageView;