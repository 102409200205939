import styled from 'styled-components/macro';

export const ProjectsWrapper = styled.section`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 10px 20px;
  margin: 160px auto 100px;

  @media (min-width: 768px) {
    margin-top: 180px;
  }

  @media (min-width: 1024px) {
    margin-top: 200px;
    font-size: 37px;
  }
`;
