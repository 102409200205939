import {
  START_LOADING_PAGE_DATA,
  STOP_LOADING_PAGE_DATA
} from '../actions/actionTypes.js';

const INITIAL_STATE = {
  pageIsLoadingData: false
};

const generalSettingsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case START_LOADING_PAGE_DATA:
      return {
        ...state,
        pageIsLoadingData: true
      };
    case STOP_LOADING_PAGE_DATA:
      return {
        ...state,
        pageIsLoadingData: false
      };
    default:
      return state;
  }
}

export default generalSettingsReducer;
