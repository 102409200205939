import React from 'react';
import PropTypes from 'prop-types';
import { ListWrapper, List } from "./styled-components";
import ListItem from '../ListItemComponent';

const ProjectsList = props => {
    return (
        <ListWrapper>
            <List>
                {props.list.map(item => (
                    <ListItem 
                        key={item.name}
                        item={item}
                        onClick={props.onClickProject}
                        activeName={props.showingProjectName}
                    />
                ))}
                {props.list.length % 3 !== 0 &&
                    <ListItem key='empty-1' onClick={() => {}} />
                }
            </List>
        </ListWrapper>
    );
};

const { array, func, string } = PropTypes;

ProjectsList.propTypes = {
    list: array.isRequired,
    onClickProject: func.isRequired,
    showingProjectName: string
};

export default ProjectsList;