import styled from 'styled-components/macro';

export const ModalWrapper = styled.div`
  &.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    height: 100%;
    background: rgba(69, 75, 96, 0.5);
  }

  .modal-content {
    position: fixed;
    top: 5%;
    right: 50%;
    margin-right: -45%;
    width: 90%;
    height: 90%;
    overflow-y: auto;
    background: #FFFFFF;
  }

  &.modal-enter {
    opacity: 0;
    .modal-content {
        right: -100%;
    }
  }

  &.modal-enter-active {
    opacity: 1;
    transition: 500ms;
    .modal-content {
        right: 50%;
        transition: 500ms ease-out;
    }
  }

  &.modal-exit {
    opacity: 1;
    .modal-content {
        right: 0;
    }
  }

  &.modal-exit-active {
    opacity: 0;
    transition: all 500ms;
    .modal-content {
        right: -100%;
        transition: all 500ms ease-out;
    }
  }
`;
