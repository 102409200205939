import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from "prop-types";

const CircleStatisticWrapper = styled.div`
  .CircleStatistic__circle {
    text-align: center;
  }
  .CircleStatistic__title {
    margin: 0;
    text-align: center;
    opacity: ${props => props.textOpacity};
  }
`;

class CircleStatisticComponent extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      radius: props.radius,
      stroke: props.stroke,
      progress: 0,
      textOpacity: 0
    };

    this.normalizedRadius = this.state.radius - this.state.stroke * 2;
    this.circumference = this.normalizedRadius * 2 * Math.PI;
  }

  componentDidMount() {
    this.circleStrokeAnimation();
  }

  componentDidUpdate(props, state) {
    if (!this.props.active && props.active) {
      this.circleStrokeAnimation();
    }
  }

  circleStrokeAnimation = () => {
    this.circleInterval = setInterval(() => {
      if (this.state.progress <= 100) {
        this.setState((state) => ({
          progress: state.progress + 1
        }));
      } else {
        this.setState({progress: 100});
        clearInterval(this.circleInterval);
        this.textOpacityAnimation();
      }
    }, 10)
  };

  textOpacityAnimation = () => {
    this.textOpacityInterval = setInterval(() => {
      if (this.state.textOpacity < 1) {
        this.setState((state) => ({
          textOpacity: state.textOpacity + 0.02
        }));
      } else {
        this.setState({textOpacity: 1});
        clearInterval(this.textOpacityInterval);
      }
    }, 10)
  };

  render() {
    const strokeDashoffset = this.circumference - this.state.progress / 100 * this.circumference;

    return (
      <CircleStatisticWrapper className={this.props.className} textOpacity={this.state.textOpacity}
                              style={{width: this.state.radius * 2}}>
        <div className="CircleStatistic__circle">
          <svg
            height={this.state.radius * 2}
            width={this.state.radius * 2}
          >
            <circle
              stroke="#58c0cc"
              fill="transparent"
              strokeWidth={this.state.stroke}
              strokeDasharray={this.circumference + ' ' + this.circumference}
              style={{strokeDashoffset}}
              r={this.normalizedRadius}
              cx={this.state.radius}
              cy={this.state.radius}
            />
            <text
              x="50%"
              y="50%"
              opacity={this.state.textOpacity}
              textAnchor="middle"
              fill="black"
              fontSize="24px"
              fontWeight="bold"
              dy=".3em"
            >
              {this.props.circleText}
            </text>
          </svg>
        </div>
        {this.props.title && <h4 className="CircleStatistic__title">{this.props.title}</h4>}
      </CircleStatisticWrapper>
    );
  }
}

CircleStatisticComponent.propTypes = {
  className: PropTypes.string,
  radius: PropTypes.number.isRequired,
  stroke: PropTypes.number.isRequired,
  title: PropTypes.string,
  circleText: PropTypes.string.isRequired,
  active: PropTypes.bool
};

export default CircleStatisticComponent;