import { createSelector } from 'reselect'

const getHomePageData = state => state.homePageData;

export const getSliderAnimationTime = createSelector(
  getHomePageData,
  (homePageData) => {
    return homePageData.sliderAnimationTime;
  }
);

export const getSliderIntervalTime = createSelector(
  getHomePageData,
  (homePageData) => {
    return homePageData.sliderIntervalTime;
  }
);