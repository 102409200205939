import React from 'react';
import {connect} from 'react-redux';

import loadingPageView from '../../hoc/loadingPageView.js';
import {AboutUsWrapper} from "./styled-components";
import CircleStatisticComponent from "../../components/circleStatisticComponent";

import kkPhoto from "../../assets/images/kkPhoto.jpeg";

class AboutUs extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      logoOpacityActive: false
    };


    setTimeout(() => {
      this.setState({
        logoOpacityActive: true
      })
    }, 2000)
  }

  render() {
    return (
      <AboutUsWrapper>
        <article>
          <h2 className="AboutUs__title">O Nas</h2>
          <div className="AboutUs__photoWrapper">
            <img className="AboutUs__KKPhoto" src={kkPhoto} alt="Zdjęcie Katarzyny Kolejkowicz" />
          </div>
          <p className="AboutUs__text">Właścicielką pracowni KK Studio jest Katarzyna Kolejkowicz, absolwentka
            Architektury Wnętrz na wydziale Architektury Politechniki Białostockiej. W projektowaniu ceni
            funkcjonalność, jasne, nowoczesne a jednocześnie przytulne wnętrza, odzwierciedlające styl życia i pasje
            domowników. Nieustannie poszerza wiedzę poprzez aktywny udział w konferencjach, targach i szkoleniach o
            tematyce wnętrzarskiej, budowlanej oraz rozwoju osobistego. Pasjonuje ją psychologia, podróże, sport oraz
            dobra książka. Pracownia kieruje się przede wszystkim projektowaniem pod indywidualny charakter klientów,
            ich pasje, styl życia, upodobania. W każdym stylu szukamy harmonii, łącząc funkcjonalność z pięknem
            projektowanych wnętrz.</p>
        </article>
        <article>
          <h2 className="AboutUs__title">KK Studio w Liczbach</h2>
          <div className="AboutUs__statsWrapper">
            <CircleStatisticComponent className="statsWrapper__stats" radius={80} stroke={6} title={"Lat Doświadczenia"} circleText={"8"} active={true}/>
            <CircleStatisticComponent className="statsWrapper__stats" radius={80} stroke={6} title={"Zakończonych Projektów"} circleText={"169"} active={true}/>
            <CircleStatisticComponent className="statsWrapper__stats" radius={80} stroke={6} title={"Zaprojektowanej Przestrzeni"} circleText={"8450m2"} active={true}/>
            <CircleStatisticComponent className="statsWrapper__stats" radius={80} stroke={6} title={"Wypitych Filiżanek Kawy"} circleText={"1825"} active={true}/>
          </div>
        </article>
        <article>
          <h2 className="AboutUs__title">Mówią o nas</h2>
          <div className="AboutUs__commentsWrapper">
            <div className="commentsWrapper__comment">
              <div className="comment__text">"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin tempor, lectus eu elementum laoreet, mauris sapien posuere nisl, at maximus odio sapien eget justo."</div>
              <div className="comment__author">Pani Marta K.</div>
            </div>
            <div className="commentsWrapper__comment commentsWrapper__comment--right">
              <div className="comment__text">"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin tempor, lectus eu elementum laoreet, mauris sapien posuere nisl, at maximus odio sapien eget justo. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin tempor, lectus eu elementum laoreet, mauris sapien posuere nisl, at maximus odio sapien eget justo."</div>
              <div className="comment__author">Pani Marta K.</div>
            </div>
          </div>
        </article>
      </AboutUsWrapper>
    );
  }
}

AboutUs.propTypes = {};

const mapStateToProps = state => {
  return {}
};

const mapDispatchToProps = {};

export default loadingPageView(connect(
  mapStateToProps,
  mapDispatchToProps
)(AboutUs));