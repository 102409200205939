import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import { ModalWrapper } from './styled-components';

class ModalComponent extends React.PureComponent {
    constructor(props) {
        super(props);
        this.el = document.createElement('div');
        this.modalRoot = document.getElementById('modal-root');
    }
    
    componentDidMount() {
        this.modalRoot.appendChild(this.el);
        document.body.classList.add('modal-opened');
        this.savePosition();
    }

    componentWillUnmount() {
        this.modalRoot.removeChild(this.el);
        document.body.classList.remove('modal-opened');
        this.returnPosition();
    }
    
    dontClose = event => {
        event.stopPropagation();
        event.preventDefault();
    };

    savePosition = () => {
        const scrollY = document.documentElement.style.getPropertyValue('--scroll-y');
        document.body.style.top = `-${scrollY}`;
    };

    returnPosition = () => {
        const scrollY = document.body.style.top;
        document.body.style.top = '';
        window.scrollTo(0, parseInt(scrollY || '0') * -1);
    };

    render() {
        const className = classNames('modal', this.props.className);

        const modal = (
            <ModalWrapper className={className} onClick={this.props.closeModal}>
                <div className="modal-content" onClick={this.dontClose}>
                    {
                        // <div className="modal-close-button">
                        // <img
                        //     src={'test'}
                        //     alt="close modal"
                        //     onClick={this.props.closeModal}
                        // />
                        // </div>
                    }

                    {this.props.children}
                </div>
            </ModalWrapper>
        );
        return ReactDOM.createPortal(modal, this.el);
    }
};

const { string, func, object } = PropTypes;

ModalComponent.propTypes = {
    closeModal: func.isRequired,
    children: object.isRequired,
    className: string
};

export default ModalComponent;