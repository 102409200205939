import React from 'react';

const withPageTitle = (WrappedComponent, title, routeConfig = {}) => {
  return class ComponentWithPageTitle extends React.Component {
    componentDidMount() {
      document.title = title;
    }
    render() {
      return <WrappedComponent {...this.props} routeConfig={routeConfig} />;
    }
  };
};

export default withPageTitle;