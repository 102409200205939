import React from 'react';
import {Route, Switch} from 'react-router-dom';

import withPageTitle from './hoc/withPageTitle'

import HomePage from './containers/HomePage';
import AboutUs from "./containers/AboutUs/AboutUs";
import Offer from "./containers/Offer/Offer";
import Contact from "./containers/Contact/Contact";
import Projects from './containers/Projects/Projects';

export default (
    <Switch>
        <Route path='/' exact component={withPageTitle(HomePage, 'KKStudio - Home')}/>
        <Route path='/o-nas' exact component={withPageTitle(AboutUs, 'KKStudio - O Nas')}/>
        <Route path='/oferta' exact component={withPageTitle(Offer, 'KKStudio - Oferta')}/>
        <Route 
            path='/projekty/:name?' 
            exact 
            component={withPageTitle(Projects, 'KKStudio - Projekty', { backUrl: '/projekty' })}
        />
        <Route path='/kontakt' exact component={withPageTitle(Contact, 'KKStudio - Kontakt')}/>
    </Switch>
);