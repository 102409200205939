import React from 'react';
import { connect } from 'react-redux';
import PropTypes from "prop-types";

import loadingPageView from '../hoc/loadingPageView.js';

import { startLoadingPageData, stopLoadingPageData } from "../actions/actions";

import { getSliderAnimationTime, getSliderIntervalTime } from '../selectors/homePageDataSelectors.js';

import FullScreenImageSlider from '../components/fullScreenImageSlider';

import image1 from "../assets/images/slider/slide1.jpeg";
import image2 from "../assets/images/slider/slide2.jpeg";
import image3 from "../assets/images/slider/slide3.jpeg";
import image4 from "../assets/images/slider/slide4.jpeg";

class HomePage extends React.Component {
  componentDidMount() {
    setTimeout(() => {
      this.props.stopLoadingPageData();
    },5000)
  }

  render() {
    const { sliderAnimationTime, sliderIntervalTime } = this.props;
    const sliderImages = [image4, image1, image2, image3];
    
    return (
      <FullScreenImageSlider
          sliderImages={sliderImages}
          sliderAnimationTime={sliderAnimationTime}
          sliderIntervalTime={sliderIntervalTime}
      />
    );
  }
}

HomePage.propTypes = {
  sliderAnimationTime: PropTypes.number.isRequired,
  sliderIntervalTime: PropTypes.number.isRequired
};

const mapStateToProps = state => {
  return {
    sliderAnimationTime: getSliderAnimationTime(state),
    sliderIntervalTime: getSliderIntervalTime(state)
  }
};

const mapDispatchToProps = {
  startLoadingPageData,
  stopLoadingPageData
};

export default loadingPageView(connect(
  mapStateToProps,
  mapDispatchToProps
)(HomePage));