import styled from 'styled-components/macro';

export const ListWrapper = styled.div`
  width: 100%;
`;

export const List = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  list-style: none;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
`;