import { combineReducers } from 'redux';

import generalSettings from './generalSettingsReducer.js';
import homePageData from './homePageDataReducer.js';
import projectsData from './projectsDataReducer.js';

const reducers = combineReducers({
  generalSettings,
  homePageData,
  projectsData
});

export default reducers;