import styled from 'styled-components/macro';

export const Item = styled.li`
  position: relative;
  width: 100%;
  cursor: pointer;
  margin-bottom: 6px;
  overflow: hidden;

  &:after {
    content: "";
    display: block;
    padding-bottom: 70%;
  }

  @media (min-width: 768px) {
    width: calc(50% - 4px);
  }

  @media (min-width: 1024px) {
    width: calc(33.3% - 4px);
  }
`;

export const EpmptyItem = styled.li`
  display: none;
  width: 100%;
  margin-bottom: 6px;
  overflow: hidden;

  &:after {
    content: "";
    display: block;
    padding-bottom: 70%;
  }
  
  @media (min-width: 768px) {
    width: calc(50% - 4px);
  }

  @media (min-width: 1024px) {
    display: block;
    width: calc(33.3% - 4px);
  }
`;

export const ItemWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .image  {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition-duration: .4s;
  }

  @media (min-width: 768px) {
    &:hover {
      .image {
        transform: scale(1.02);
      }
    }
  }
`;

export const HoverBox = styled.div`
  opacity: 1;
  transition-duration: .4s;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(88, 192, 204, .6);

  @media (min-width: 1024px) {
    opacity: 0;

    &:hover, &.active {
      opacity: 1;
    }
  }
`;

export const Content = styled.div`
  padding: 0 15px;

  .title {
    font-size: 16px;
    color: #232323;
    text-align: center;
    margin: 0 0 5px;
  }
  
  .divider {
    display: block;
    width: 90%;
    height: 0;
    margin: 0 auto 5px;
    border-bottom: 1px solid #000000;
  }

  .area {
    font-size: 13px;
    text-align: center;
    margin: 0;
  }

  @media (min-width: 768px) {
    .title {
      font-size: 18px;
      margin: 0 0 10px;
    }
    .divider {
      margin-bottom: 10px;
    }
    .area {
      font-size: 15px;
    }
  }
`;