import React from "react";
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

const SliderImage = styled.div(({ index, imageUrl, activeImageIndex, sliderAnimationTime }) => {
  const opacity = index === activeImageIndex ? 1 : 0;
  return {
    'position': 'fixed',
    'background-image': `url("${imageUrl}")`,
    'height': '100vh',
    'width': '100vw',
    'background-position': 'center',
    'background-repeat': 'no-repeat',
    'background-size': 'cover',
    'opacity': opacity,
    'transition': `opacity ${sliderAnimationTime}s linear`,
    'overflow': 'none'
}});

class FullScreenImageSlider extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeImageIndex: 0
    };
  };

  componentDidMount() {
    this.sliderInterval = setInterval(() => {
      this.setState((state, props) => {
        if (state.activeImageIndex === props.sliderImages.length - 1) {
          return {
            activeImageIndex: 0
          }
        }
        return {
          activeImageIndex: state.activeImageIndex + 1
        }
      })
    }, this.props.sliderIntervalTime);
  };

  componentWillUnmount() {
    clearInterval(this.sliderInterval);
  };
  
  render() {
    return (
      <div className="full-screen-image-slider">
        {this.props.sliderImages.map(
          (image, index) =>
            <SliderImage
              key={`key-${index}`}
              imageUrl={image}
              index={index}
              activeImageIndex={this.state.activeImageIndex}
              sliderAnimationTime={this.props.sliderAnimationTime}
            />
        )}
      </div>
    );
  };
};

FullScreenImageSlider.propTypes = {
  sliderImages: PropTypes.array.isRequired,
  sliderAnimationTime: PropTypes.number.isRequired,
  sliderIntervalTime: PropTypes.number.isRequired,
};

export default FullScreenImageSlider;