import {
  SET_HOME_PAGE_DATA,
} from '../actions/actionTypes.js';

const INITIAL_STATE = {
  sliderAnimationTime: 4,
  sliderIntervalTime: 10000
};

const homePageDataReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_HOME_PAGE_DATA:
      return state;
    default:
      return state;
  }
}

export default homePageDataReducer;
