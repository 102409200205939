import { SET_PROJECTS_LIST_DATA } from '../actions/actionTypes.js';
import { KK_STUDIO_PROJECTS } from '../constants/projects'
  
const INITIAL_STATE = {
    projectsList: KK_STUDIO_PROJECTS
};

const projectsDataReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_PROJECTS_LIST_DATA:
            return {
                ...state,
                projectsList: action.payload.projectsList
            };
        default:
            return state;
    }
};

export default projectsDataReducer;
