import styled from 'styled-components/macro';

export const HeaderMenuWrapper = styled.header`
    position: fixed;
    width: 100%;
    margin-top: ${props => props.shortMenu ? "0" : "10px"};
    top:0;
    left: 0;
    z-index: 100;
    transition: top 500ms cubic-bezier(0.77,0.2,0.05,1.0);
`;

export const LogoImage = styled.div`
    position: relative;
    display: block;
    width: 120px;
    margin: 0 auto;
    background-color: white;
    z-index: 110;
    cursor: pointer;
    top: ${props => props.shortMenu ? "-120px" : "0"};
    transition: top 500ms cubic-bezier(0.77,0.2,0.05,1.0);
    @media (min-width: 1024px) {
      width: 160px;
      top: ${props => props.shortMenu ? "-160px" : "0"};
    }
    img {
      display:block;
      width: 100%;
      padding: 10px;
      @media (min-width: 1024px) {
        padding: 25px;
      }
    }
`;

export const MenuBar = styled.div`
  position: absolute;
  background-color: white;
  top: ${props => props.shortMenu ? "0" : "40px"};
  left: 0;
  right: 0;
  height: 40px;
  z-index: 100;
  transition: top 500ms cubic-bezier(0.77,0.2,0.05,1.0);
  @media (min-width: 1024px) {
    top: ${props => props.shortMenu ? "0" : "55px"};
  }
  .menu-bar__wrapper {
    max-width: 1600px;
    margin: 0 auto;
    position: relative;
    .wrapper__menu-title {
      display: none;
      font-weight: bold;
      @media (min-width: 768px) {
        display: block;
        font-family: "Lucida Console", sans-serif;
        margin: 0;
        padding: 11px;
        font-size: 16px;
      }
      @media (min-width: 1024px) {
        margin-left: 30px;
        font-size: 18px;
      }
    }
  }
`;

export const HamburgerButton = styled.button`
    float: right;
    background-color: transparent;
    border: none;
    margin-right: 5px;
    margin-top: 5px;
    outline: none;
    cursor: pointer;
    span {
          position: relative;
          z-index: 110;
          display: block;
          width: 30px;
          height: 4px;
          margin: 4px 0;
          border-radius: 3px;
          background: #cdcdcd;
          transform-origin: 4px 0;
          transition:
            transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
            background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
            opacity 0.55s ease;
    }
    span:nth-child(1) {
        ${props => props.isMenuOpen && "transform: rotate(45deg) translate(4px, -2px);"}
    }
    span:nth-child(2) {
        ${props => props.isMenuOpen && "opacity: 0;"}
    }
    span:nth-child(3) {
        ${props => props.isMenuOpen && "transform: rotate(-45deg) translate(0px, 1px);"}
    }
    
  @media (min-width: 768px) {
    display: none;
  }
`;

export const NavMenu = styled.ul`
  position: absolute;
  top: ${props => {
    if(props.isMenuOpen && props.shortMenu) {
      return "20px";
    } else if (props.isMenuOpen && !props.shortMenu) {
      return "-70px";
    } else if (!props.isMenuOpen && !props.shortMenu) {
      return "-390px";
    } else {
      return "-390px";
    }
  }};
  padding-top: ${props => props.shortMenu ? "0" : "160px"};
  padding-bottom: 20px;
  left: 0;
  right: 0;
  background-color: rgba(255,255,255,0.9);
  list-style: none;
  transition: all 500ms cubic-bezier(0.77,0.2,0.05,1.0);
  z-index: -1;
  li {
    margin: 5px 0;
    font-family: "Lucida Console", sans-serif;
    font-size: 25px;
    a {
      text-decoration: none;
      color: #3f3f3f;
      text-transform: uppercase;
      font-weight: bold;
      &.active {
        color: #58c0cc;
      }
      &:hover {
        color: #58c0cc;
      }
    }
  }
  @media (min-width: 768px) {
    padding: 6px;
    margin: 0;
    top: 0;
    left: unset;
    right: 0;
    z-index: 100;
    li {
      display: inline-block;
      font-size: 16px;
      padding-right: 10px;
    }
  }
  @media (min-width: 1024px) {
    right: 30px;
    padding: 5px;
    li {
      font-size: 18px;
      padding-right: 20px;
    }
  }
`;